
    import { defineComponent } from 'vue';
    import { chevronBackOutline, chevronForwardSharp } from 'ionicons/icons';
    import { store } from '../../store/store';
    import { LanguageMixin } from '@/mixins/LanguageMixin';
    import { SharedMixin } from '../../mixins/SharedMixin';
    import { MemberApiClient, ActivateAccountRequest } from '../../generated';
    import {
        IonIcon,
        IonButton,
        IonImg,
        IonInput
    } from '@ionic/vue';

    export default defineComponent({
        name: 'Activate',
        components: {
            IonIcon,
            IonButton,
            IonImg,
            IonInput
        },
        mixins: [SharedMixin, LanguageMixin],
        setup() {
            let loggedInUser = {};

            loggedInUser = store.state.loggedInUser;

            return {
                chevronBackOutline,
                chevronForwardSharp,
                loggedInUser
            }
        },
        watch: {
            password1: function (newVal, oldVal) {
                this.password = this.password1 + this.password2 + this.password3 + this.password4 + this.password5;
                console.log(this.password)
            },
            password2: function (newVal, oldVal) {
                this.password = this.password1 + this.password2 + this.password3 + this.password4 + this.password5;
                console.log(this.password)
            },
            password3: function (newVal, oldVal) {
                this.password = this.password1 + this.password2 + this.password3 + this.password4 + this.password5;
                console.log(this.password)
            },
            password4: function (newVal, oldVal) {
                this.password = this.password1 + this.password2 + this.password3 + this.password4 + this.password5;
                console.log(this.password)
            },
            password5: function (newVal, oldVal) {
                this.password = this.password1 + this.password2 + this.password3 + this.password4 + this.password5;
                console.log(this.password)
                if (this.password.length == 5 && this.passwordAgain.length < 1) {
                    this.passwordScreen2 = true;
                    this.passwordScreen1 = false;
                    this.focusOnFirstField('f6')
                }
            },
            password6: function (newVal, oldVal) {
                this.passwordAgain = this.password6 + this.password7 + this.password8 + this.password9 + this.password10;
                console.log(this.passwordAgain)
            },
            password7: function (newVal, oldVal) {
                this.passwordAgain = this.password6 + this.password7 + this.password8 + this.password9 + this.password10;
                console.log(this.passwordAgain)
            },
            password8: function (newVal, oldVal) {
                this.passwordAgain = this.password6 + this.password7 + this.password8 + this.password9 + this.password10;
                console.log(this.passwordAgain)
            },
            password9: function (newVal, oldVal) {
                this.passwordAgain = this.password6 + this.password7 + this.password8 + this.password9 + this.password10;
                console.log(this.passwordAgain)
            },
            password10: function (newVal, oldVal) {
                this.passwordAgain = this.password6 + this.password7 + this.password8 + this.password9 + this.password10;
                console.log(this.passwordAgain)
                if (this.passwordAgain.length == 5) {
                    if (this.passwordAgain == this.password) {
                        this.submitpassword()
                        this.errorMessage = false;
                        this.passwordScreen1 = false;
                    } else {
                        this.errorMessage = true;
                    }
                }
            },
            
        },
        data() {
            return {
                fboNumber: '',
                password: '',
                passwordAgain: '',
                password1: '',
                password2: '',
                password3: '',
                password4: '',
                password5: '',
                password6: '',
                password7: '',
                password8: '',
                password9: '',
                password10: '',
                passwordScreen1: true,
                passwordScreen2: false,
                successScreen: false,
                errorMessage: false,
                errorMessage1: false,
                userName: null
            }
        },
        ionViewDidEnter() {
            if (localStorage.getItem('userName')) {
                // @ts-ignore
                this.userName = localStorage.getItem('userName');
            }
            if (localStorage.getItem('loggedIn') == 'true') {
                this.$router.push('/home').then(
                    () => {
                        window.location.reload();
                    }
                );
            }
            if (!this.$route.query.t) {
                this.$router.push('/register').then(
                    () => {
                        window.location.reload();
                    }
                );
            }
        },
        methods: {
            goBack: function () {
                this.passwordScreen1 = true;
                this.passwordScreen2 = false;
            },
            submitpassword: function () {
                if (this.$route.query.t != null) {
                    console.log('submit password');

                    const apiClient = new MemberApiClient();
                    const activateAccountRequest = new ActivateAccountRequest();

                    activateAccountRequest.newPassword = this.password;
                    activateAccountRequest.newPasswordConfirm = this.passwordAgain;
                    activateAccountRequest.token = this.$route.query.t.toString();

                    apiClient.activateAcount(activateAccountRequest).then(result => {
                        if (result != null && result.status == 200) {
                            this.successScreen = true;
                            this.passwordScreen2 = false;
                            this.errorMessage = false;
                            this.errorMessage1 = false;
                        } else {
                            // foutmelding tonen
                            console.log('fout')
                            this.errorMessage1 = true;
                        }
                    }).catch(error => {
                        //this.isError = true;
                        this.errorMessage1 = true;
                    });
                }
            },
            focusOnFirstField: function (element: string) {
                const tid = setInterval(function () {
                    if (document.readyState !== 'complete') return;
                    clearInterval(tid);
                    const firstField: HTMLElement = document.getElementById(element) as HTMLElement;
                    firstField.focus();
                }, 100);
            },
            gotoNextField: function (e: Event) {
                const element: HTMLElement = e.srcElement as HTMLElement;

                // @ts-ignore
                if (e?.target?.value.length > 0) {
                    // @ts-ignore
                    element.parentElement.nextElementSibling?.childNodes[0].focus();
                }

            },
            deleteOrPreviousField: function (e: Event) {
                const element: HTMLElement = e.srcElement as HTMLElement;

                // @ts-ignore
                if (e?.target?.value.length < 1) {
                    // @ts-ignore
                    element.parentElement.previousElementSibling?.childNodes[0].focus();
                }
            }
        }
    });
